var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-radio-input", class: { "is-error": _vm.error } },
    [
      _c(
        "label",
        { class: { active: _vm.isChecked }, attrs: { for: _vm.id } },
        [
          _vm._t("default"),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.label))])
        ],
        2
      ),
      _vm._v(" "),
      _c("input", {
        attrs: { id: _vm.id, type: "radio" },
        domProps: { value: _vm.value, checked: _vm.isChecked },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event.target.value)
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }