<template>
    <div :class="{ 'is-error': errors.length }" class="custom-number-input">
        <label :for="id">{{ label }}:</label>
        <div class="custom-number-input__inner">
            <input
                :id="id"
                :value="value"
                placeholder="0"
                @change="val = $event.target.value"
            />
            <span v-if="unit" class="unit">{{ unit }}</span>
            <button type="button" class="control plus" @click="change(1)">
                <PlusIcon />
            </button>
            <span class="seperator"></span>
            <button type="button" class="control minus" @click="change(-1)">
                <MinusIcon />
            </button>
        </div>
        <div>
            <span v-if="errors.length" class="error-text">{{ errors[0] }}</span>
        </div>
    </div>
</template>

<script>
//icons
import PlusIcon from '@icons/plus-circle.svg'
import MinusIcon from '@icons/minus-circle.svg'

export default {
    components: {
        PlusIcon,
        MinusIcon
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        unit: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            required: true
        },
        rules: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            touched: false
        }
    },
    computed: {
        val: {
            get() {
                return this.value
            },
            set(val) {
                this.touched = true
                this.$emit('input', val.replace(',', '.'))
            }
        },
        errors() {
            if (!this.touched) {
                return []
            }
            return this.rules
                .map((rule) => rule(this.value))
                .filter(
                    (validationResult) => typeof validationResult === 'string'
                )
        }
    },
    methods: {
        change(val) {
            this.val = String(Number(this.val) + val)
        }
    }
}
</script>

<style lang="scss" scoped>
$mobile: '#{$media-xs}, #{$screen} and (max-width: #{$screen-sm-max}) and (orientation: landscape)';

.custom-number-input {
    width: 100%;
    margin-bottom: 26px;
    &.is-error {
        .custom-number-input__inner input {
            border-color: rgb(221, 16, 16);
            color: rgb(221, 16, 16);
        }
    }
    label {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 5px;
        display: block;
        width: 320px;
        @media #{$mobile} {
            width: 100%;
        }
    }
    &__inner {
        margin-right: 7px;
        @media #{$mobile} {
            margin-right: 22px;
        }
        display: flex;
        align-items: center;
        position: relative;
        input {
            justify-content: space-between;
            width: 320px;
            @media #{$mobile} {
                width: 100%;
            }
            height: 60px;
            border: 1px solid #000;
            border-radius: 12px;
            padding: 0 10px 0 20px;
            box-sizing: border-box;
            font-size: 20px;
            font-weight: 700;
            font-style: normal;
            color: #000;
            background-color: #fff;
            box-shadow: 3px 1px 2px 1px rgba(0, 0, 0, 0.4);
            text-align: center;
        }
        .unit {
            font-size: 16px;
            color: #000;
            font-weight: 700;
            margin-left: 12px;
            position: absolute;
            right: -32px;
        }
        .seperator {
            height: 1px;
            width: 20px;
            background: #000;
            display: block;
            position: absolute;
            top: 29px;
            right: 6px;
        }
        .control {
            @include n-button-reset();
            position: absolute;
            right: 6px;
            cursor: pointer;
            &.minus {
                bottom: 6px;
            }
            &.plus {
                top: 6px;
            }
        }
    }
    .error-text {
        color: rgb(221, 16, 16);
        display: block;
        margin-top: 5px;
        margin-left: 8px;
        position: absolute;
        font-size: 13px;
    }
}
</style>
