<template>
    <div class="bmi-calc">
        <section v-if="!resultsAreShown" class="bmi-calc__form">
            <form class="bmi-calc__form-content">
                <div class="bmi-calc__sex-container">
                    <label class="bmi-calc__sex-label">Płeć:</label>
                    <div class="bmi-calc__sex">
                        <BMIRadioInput
                            id="female"
                            v-model="sex"
                            label="Kobieta"
                            value="F"
                            :error="!sex && validated"
                        >
                            <FemaleIcon />
                        </BMIRadioInput>
                        <BMIRadioInput
                            id="male"
                            v-model="sex"
                            label="Mężczyzna"
                            value="M"
                            :error="!sex && validated"
                        >
                            <MaleIcon />
                        </BMIRadioInput>
                    </div>
                    <span v-if="!sex && validated" class="bmi-calc__sex-error"
                        >Pole jest wymagane.</span
                    >
                </div>
                <div class="bmi-calc__input">
                    <BMINumberInput
                        id="age"
                        ref="age"
                        v-model="age"
                        label="Wiek"
                        unit="lat"
                        :rules="[required, notNegative, isAdult, isAlive]"
                    />
                </div>
                <div class="bmi-calc__input">
                    <BMINumberInput
                        id="height"
                        ref="height"
                        v-model="height"
                        label="Wzrost"
                        unit="cm"
                        :rules="[required, notNegative, isHeight]"
                    />
                </div>
                <div class="bmi-calc__input">
                    <BMINumberInput
                        id="weight"
                        ref="weight"
                        v-model="weight"
                        label="Waga"
                        unit="kg"
                        :rules="[required, notNegative, isWeight]"
                    />
                </div>
                <div class="bmi-calc__input">
                    <button
                        class="bmi-calc__btn bmi-calc__btn--primary big"
                        @click.prevent="submitForm"
                    >
                        Oblicz BMI
                    </button>
                </div>
            </form>
        </section>
        <section v-else class="bmi-calc__results">
            <h2 class="bmi-calc__header">Twój wskaźnik BMI wynosi:</h2>
            <div class="bmi-calc__scale-container">
                <div class="bmi-calc__scale">
                    <span
                        v-for="range in scale"
                        :key="range.id"
                        :style="{ backgroundColor: range.color }"
                        class="bmi-calc__scale-item"
                    ></span>
                    <span class="bmi-calc__indicator">
                        <span
                            class="bmi-calc__value"
                            :style="{
                                backgroundColor: computedRange.color,
                                left: isMobile
                                    ? mobileMargin
                                    : computedPosition + '%'
                            }"
                        >
                            {{ calculatedBMI }}
                        </span>
                        <ArrowIndicatorIcon
                            :style="{
                                left: isMobile
                                    ? mobileMargin
                                    : computedPosition + '%'
                            }"
                            class="bmi-calc__svg-indicator"
                        />
                    </span>
                </div>
            </div>
            <div class="bmi-calc__text">
                <h2 class="bmi-calc__title">{{ computedRange.name }}</h2>
                <!-- eslint-disable vue/no-v-html -->
                <p
                    v-if="computedRange.description"
                    class="bmi-calc__description"
                    v-html="computedRange.description"
                ></p>
                <!-- eslint-enable vue/no-v-html -->
            </div>
            <a
                v-if="
                    computedRange.btnPrimaryUrl && computedRange.btnPrimaryText
                "
                class="bmi-calc__btn bmi-calc__btn--primary"
                :href="computedRange.btnPrimaryUrl"
                :style="{
                    backgroundColor: computedRange.buttonColor
                }"
            >
                {{ computedRange.btnPrimaryText }}</a
            >
            <a
                v-if="computedRange.btnOnlineUrl && computedRange.btnOnlineText"
                class="bmi-calc__btn bmi-calc__btn--outline"
                :href="computedRange.btnOnlineUrl"
                :style="{
                    backgroundColor: computedRange.buttonOnlineColor
                }"
            >
                {{ computedRange.btnOnlineText }}</a
            >
        </section>
    </div>
</template>

<script>
import BMINumberInput from './BMINumberInput.vue'
import BMIRadioInput from './BMIRadioInput.vue'
import {
    required,
    isHeight,
    isWeight,
    isAdult,
    isAlive,
    notNegative
} from './rules'

//icons
import MaleIcon from '@icons/male.svg'
import FemaleIcon from '@icons/female.svg'
import ArrowIndicatorIcon from '@icons/arrow-indicator.svg'

export default {
    scopeName: 'bmi',
    components: {
        BMINumberInput,
        BMIRadioInput,
        MaleIcon,
        FemaleIcon,
        ArrowIndicatorIcon
    },
    inheritAttrs: false,
    data() {
        return {
            isMobile: false,
            scale: [],
            height: '165',
            weight: '60',
            age: '20',
            sex: '',
            resultsAreShown: false,
            validated: false,
            resultsPage: window.resultsPageUrl
        }
    },
    computed: {
        mobileMargin() {
            if (this.computedPosition > 92.3) {
                return '92.3%'
            } else if (this.computedPosition < 9.15) {
                return '9.15%'
            } else {
                return this.computedPosition + '%'
            }
        },
        calculatedBMI() {
            let bmiResults = Cookies.get('bmiResults')
            if (bmiResults) {
                const results = JSON.parse(bmiResults)
                if (results.height && results.weight) {
                    const height = Number(results.height)
                    const weight = Number(results.weight) * 100000
                    return (weight / (height * height) / 10).toFixed(1)
                } else {
                    return null
                }
            } else {
                if (this.height && this.weight) {
                    const height = Number(this.height)
                    const weight = Number(this.weight) * 100000
                    return (weight / (height * height) / 10).toFixed(1)
                } else {
                    return null
                }
            }
        },
        computedRange() {
            const scale = [...this.scale]
            return (
                scale
                    .reverse()
                    .find((item) => +this.calculatedBMI >= +item.rangeFrom) ||
                this.scale[0]
            )
        },
        nextRange() {
            const index = this.scale.indexOf(this.computedRange) + 1
            return index === this.scale.length ? null : this.scale[index]
        },
        computedPosition() {
            const rangeFrom =
                +this.computedRange.rangeFrom || this.nextRange.rangeFrom - 6
            const rangeSize = this.nextRange
                ? +this.nextRange.rangeFrom - rangeFrom
                : 5
            const position =
                ((this.calculatedBMI - rangeFrom) / rangeSize) * 16.66 +
                16.66 * this.scale.indexOf(this.computedRange)
            return (
                (position > 100 && '100') || (position < 0 && '0') || position
            )
        }
    },
    created() {
        this.scale = [...window.calcBMI]
        this.isMobile = window.innerWidth < 768
        this.resultsPageUrl = window.resultsPageUrl
        if (this.isCookieSet() && this.isResultsPage()) {
            this.resultsAreShown = true
        }
    },
    methods: {
        validate(refs) {
            return (
                refs.filter((ref) => {
                    ref.touched = true
                    return ref.errors.length
                }).length === 0
            )
        },
        submitForm() {
            const r = this.$refs
            if (this.validate([r.age, r.height, r.weight]) && this.sex !== '') {
                const isResultsPageSet = this.resultsPageUrl.length > 0
                if (isResultsPageSet) {
                    Cookies.set(
                        'bmiResults',
                        JSON.stringify({
                            age: this.$refs.age.value,
                            height: this.$refs.height.value,
                            weight: this.$refs.weight.value,
                            sex: this.sex
                        })
                    )
                    window.location.href = this.resultsPage
                } else {
                    this.resultsAreShown = true
                }
            }
            this.validated = true
        },
        required,
        isHeight,
        isWeight,
        isAdult,
        notNegative,
        isAlive,
        isCookieSet() {
            if (Cookies.get('bmiResults')) {
                return true
            }
        },
        isResultsPage() {
            const calcResults = document.querySelector('.bmi-calc-results')
            if (calcResults) {
                return true
            }
        }
    }
}
</script>

<style lang="scss">
$mobile: '#{$media-xs}, #{$screen} and (max-width: #{$screen-sm-max}) and (orientation: landscape)';

.bmi-calc {
    background: #f1f3f9;
    padding: 20px 5px 40px;
    margin-bottom: 20px;
    @media #{$mobile} {
        padding: 20px 5px 90px;
    }
    font-family: Arial;
    max-width: 100%;

    &__sex {
        display: flex;

        @media (min-width: 360px) {
            margin-right: 10px;
        }
        &-label {
            margin-top: 8px;
            font-weight: 700;
            font-size: 16px;
        }
        &-container {
            position: relative;
            margin-bottom: 5px;
            text-align: center;
        }
        &-error {
            color: rgb(221, 16, 16);
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__form-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__input {
        margin-left: auto;
        margin-right: auto;
        max-width: 320px;

        @media #{$mobile} {
            width: 100%;
            padding: 0px 19px 0 14px;
        }
    }

    &__svg-indicator {
        position: absolute;
        bottom: 51px;
        margin-left: -12px;
    }

    &__value {
        font-size: 20px;
        font-weight: 700;
        color: #000;
        margin-bottom: 10px;
        padding: 20px;
        border-radius: 50%;
        width: 75px;
        height: 75px;
        margin-left: -39px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 71px;
    }

    &__text,
    &__scale {
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        @media #{$media-lg} {
            width: 600px;
        }
    }

    &__results {
        padding: 0 6px;
    }

    &__text {
        padding: 1px 20px;
        @media #{$media-sm-to-md} {
            padding: 0px 40px;
        }
    }

    &__scale {
        display: flex;
        position: relative;
        margin-top: 150px;

        &-container {
            margin-left: 40px;
            margin-right: 40px;
            @media #{$media-xs} {
                margin-left: 8px;
                margin-right: 8px;
            }
        }

        &-item {
            display: block;
            width: 16.6666%;
            height: 44px;
        }
    }

    &__title,
    &__header {
        font-size: 26px;
        text-align: center;
        margin-top: 45px;
        margin-bottom: 20px;
    }

    &__header {
        margin-top: 45px;

        @media #{$mobile} {
            margin-top: 20px;
        }
    }

    &__description {
        font-size: 16px;
        margin-bottom: 26px;
    }

    &__btn {
        @include n-button-reset();

        margin-top: 20px;
        font-size: 16px;
        box-shadow: 2px 1px 2px 0px rgba(0, 0, 0, 0.3);
        display: block;
        border-radius: 8px;
        padding: 18px;
        font-weight: 700;
        width: 250px;

        @media #{$mobile} {
            width: 100%;
            max-width: 280px;
        }
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        transition: all 0.3s ease;

        &--primary {
            @include n-theme {
                background: $sherpa-blue;
                color: #fff;
                &:hover,
                &:focus {
                    background: $dark-green !important; // to overwrite inline background styles
                    color: #fff;
                    outline-color: transparent;
                }
            }
        }

        &.big {
            font-size: 20px;
            padding: 14px;
        }

        &--outline {
            @include n-theme {
                background: #fff;
                border: 1px solid transparent;
                &:hover,
                &:focus {
                    background: #f0f0f4;
                    outline-color: transparent;
                    border-color: transparent;
                }
            }
        }
    }
}
</style>
