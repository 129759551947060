<template>
    <div :class="{ 'is-error': error }" class="custom-radio-input">
        <label :class="{ active: isChecked }" :for="id">
            <slot />
            <span>{{ label }}</span>
        </label>

        <input
            :id="id"
            type="radio"
            :value="value"
            :checked="isChecked"
            @input="$emit('input', $event.target.value)"
        />
    </div>
</template>

<script>
export default {
    model: {
        prop: 'modelValue',
        event: 'input'
    },
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        error: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        unit: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            required: true
        }
    },

    computed: {
        isChecked() {
            return this.modelValue === this.value
        }
    }
}
</script>
<style lang="scss" scoped>
$mobile: '#{$media-xs}, #{$screen} and (max-width: #{$screen-sm-max}) and (orientation: landscape)';

.custom-radio-input {
    &.is-error {
        label {
            border: 1px solid rgb(221, 16, 16);
        }
    }
}

input {
    display: none;
}
label {
    cursor: pointer;
    display: block;
    width: 120px;
    height: 120px;
    border-radius: 12px;
    background: #5ac6b2;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 12px 32px 20px;
    @media #{$mobile} {
        margin: 12px 6px 20px;
    }
    @media (min-width: 360px) {
        margin: 12px 18px 20px;
    }
    opacity: 0.66;
    position: relative;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.35);

    svg {
        margin-bottom: 12px;
    }

    span {
        position: absolute;
        bottom: 8px;
        font-weight: 700;
    }

    &.active {
        background: #62d1bd;
        opacity: 1;
        box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.6);
    }
}
</style>
