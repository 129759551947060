var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bmi-calc" }, [
    !_vm.resultsAreShown
      ? _c("section", { staticClass: "bmi-calc__form" }, [
          _c("form", { staticClass: "bmi-calc__form-content" }, [
            _c("div", { staticClass: "bmi-calc__sex-container" }, [
              _c("label", { staticClass: "bmi-calc__sex-label" }, [
                _vm._v("Płeć:")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "bmi-calc__sex" },
                [
                  _c(
                    "BMIRadioInput",
                    {
                      attrs: {
                        id: "female",
                        label: "Kobieta",
                        value: "F",
                        error: !_vm.sex && _vm.validated
                      },
                      model: {
                        value: _vm.sex,
                        callback: function($$v) {
                          _vm.sex = $$v
                        },
                        expression: "sex"
                      }
                    },
                    [_c("FemaleIcon")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "BMIRadioInput",
                    {
                      attrs: {
                        id: "male",
                        label: "Mężczyzna",
                        value: "M",
                        error: !_vm.sex && _vm.validated
                      },
                      model: {
                        value: _vm.sex,
                        callback: function($$v) {
                          _vm.sex = $$v
                        },
                        expression: "sex"
                      }
                    },
                    [_c("MaleIcon")],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              !_vm.sex && _vm.validated
                ? _c("span", { staticClass: "bmi-calc__sex-error" }, [
                    _vm._v("Pole jest wymagane.")
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bmi-calc__input" },
              [
                _c("BMINumberInput", {
                  ref: "age",
                  attrs: {
                    id: "age",
                    label: "Wiek",
                    unit: "lat",
                    rules: [
                      _vm.required,
                      _vm.notNegative,
                      _vm.isAdult,
                      _vm.isAlive
                    ]
                  },
                  model: {
                    value: _vm.age,
                    callback: function($$v) {
                      _vm.age = $$v
                    },
                    expression: "age"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bmi-calc__input" },
              [
                _c("BMINumberInput", {
                  ref: "height",
                  attrs: {
                    id: "height",
                    label: "Wzrost",
                    unit: "cm",
                    rules: [_vm.required, _vm.notNegative, _vm.isHeight]
                  },
                  model: {
                    value: _vm.height,
                    callback: function($$v) {
                      _vm.height = $$v
                    },
                    expression: "height"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bmi-calc__input" },
              [
                _c("BMINumberInput", {
                  ref: "weight",
                  attrs: {
                    id: "weight",
                    label: "Waga",
                    unit: "kg",
                    rules: [_vm.required, _vm.notNegative, _vm.isWeight]
                  },
                  model: {
                    value: _vm.weight,
                    callback: function($$v) {
                      _vm.weight = $$v
                    },
                    expression: "weight"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "bmi-calc__input" }, [
              _c(
                "button",
                {
                  staticClass: "bmi-calc__btn bmi-calc__btn--primary big",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submitForm.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("\n                    Oblicz BMI\n                ")]
              )
            ])
          ])
        ])
      : _c("section", { staticClass: "bmi-calc__results" }, [
          _c("h2", { staticClass: "bmi-calc__header" }, [
            _vm._v("Twój wskaźnik BMI wynosi:")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "bmi-calc__scale-container" }, [
            _c(
              "div",
              { staticClass: "bmi-calc__scale" },
              [
                _vm._l(_vm.scale, function(range) {
                  return _c("span", {
                    key: range.id,
                    staticClass: "bmi-calc__scale-item",
                    style: { backgroundColor: range.color }
                  })
                }),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "bmi-calc__indicator" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "bmi-calc__value",
                        style: {
                          backgroundColor: _vm.computedRange.color,
                          left: _vm.isMobile
                            ? _vm.mobileMargin
                            : _vm.computedPosition + "%"
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.calculatedBMI) +
                            "\n                    "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("ArrowIndicatorIcon", {
                      staticClass: "bmi-calc__svg-indicator",
                      style: {
                        left: _vm.isMobile
                          ? _vm.mobileMargin
                          : _vm.computedPosition + "%"
                      }
                    })
                  ],
                  1
                )
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "bmi-calc__text" }, [
            _c("h2", { staticClass: "bmi-calc__title" }, [
              _vm._v(_vm._s(_vm.computedRange.name))
            ]),
            _vm._v(" "),
            _vm.computedRange.description
              ? _c("p", {
                  staticClass: "bmi-calc__description",
                  domProps: { innerHTML: _vm._s(_vm.computedRange.description) }
                })
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.computedRange.btnPrimaryUrl && _vm.computedRange.btnPrimaryText
            ? _c(
                "a",
                {
                  staticClass: "bmi-calc__btn bmi-calc__btn--primary",
                  style: {
                    backgroundColor: _vm.computedRange.buttonColor
                  },
                  attrs: { href: _vm.computedRange.btnPrimaryUrl }
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(_vm.computedRange.btnPrimaryText)
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.computedRange.btnOnlineUrl && _vm.computedRange.btnOnlineText
            ? _c(
                "a",
                {
                  staticClass: "bmi-calc__btn bmi-calc__btn--outline",
                  style: {
                    backgroundColor: _vm.computedRange.buttonOnlineColor
                  },
                  attrs: { href: _vm.computedRange.btnOnlineUrl }
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(_vm.computedRange.btnOnlineText)
                  )
                ]
              )
            : _vm._e()
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }