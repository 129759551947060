var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "custom-number-input",
      class: { "is-error": _vm.errors.length }
    },
    [
      _c("label", { attrs: { for: _vm.id } }, [
        _vm._v(_vm._s(_vm.label) + ":")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "custom-number-input__inner" }, [
        _c("input", {
          attrs: { id: _vm.id, placeholder: "0" },
          domProps: { value: _vm.value },
          on: {
            change: function($event) {
              _vm.val = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _vm.unit
          ? _c("span", { staticClass: "unit" }, [_vm._v(_vm._s(_vm.unit))])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "control plus",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.change(1)
              }
            }
          },
          [_c("PlusIcon")],
          1
        ),
        _vm._v(" "),
        _c("span", { staticClass: "seperator" }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "control minus",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.change(-1)
              }
            }
          },
          [_c("MinusIcon")],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _vm.errors.length
          ? _c("span", { staticClass: "error-text" }, [
              _vm._v(_vm._s(_vm.errors[0]))
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }